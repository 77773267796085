import ahoy from "ahoy.js";
import AnalyticsConfig from "analytics";

export enum TrackingEvents {
  TRIAGE_CLICKED_GENERAL_COACHING = "TRIAGE_CLICKED_GENERAL_COACHING",
  TRIAGE_CLICKED_THERAPY = "TRIAGE_CLICKED_THERAPY",
  TRIAGE_CLICKED_SPEECH_LANGUAGE_PATHOLOGY = "TRIAGE_CLICKED_SPEECH_LANGUAGE_PATHOLOGY",
  TRIAGE_CLICKED_SPEAK_WITH_A_CARE_NAVIGATOR = "TRIAGE_CLICKED_SPEAK_WITH_A_CARE_NAVIGATOR",
  TRIAGE_STARTED_QUESTIONNAIRE = "TRIAGE_STARTED_QUESTIONNAIRE",
  TRIAGE_ASSESSMENT_QUESTION_COMPLETED = "TRIAGE_ASSESSMENT_QUESTION_COMPLETED",
  TRIAGE_COMPLETED_QUESTIONNAIRE = "TRIAGE_COMPLETED_QUESTIONNAIRE",
  CLICKED_SPEAK_WITH_A_CARE_NAVIGATOR = "CLICKED_SPEAK_WITH_A_CARE_NAVIGATOR",
  CLICKED_BOOK_TIME_WITH_A_THERAPIST = "CLICKED_BOOK_TIME_WITH_A_THERAPIST",
  CLICKED_GENERAL_COACHING = "CLICKED_GENERAL_COACHING",
  CLICKED_START_ASSESSMENT = "CLICKED_START_ASSESSMENT",
  CLICKED_CHILD_AGE_NEXT = "CLICKED_CHILD_AGE_NEXT",
  CLICKED_HIDE_PROGRAM_DETAILS = "CLICKED_HIDE_PROGRAM_DETAILS",
  CLICKED_SHOW_PROGRAM_DETAILS = "CLICKED_SHOW_PROGRAM_DETAILS",
  CLICKED_PROGRAM_SIGN_UP = "CLICKED_PROGRAM_SIGN_UP",
  CLICKED_STATE = "CLICKED_STATE",
  CREATED_ACCOUNT = "CREATED_ACCOUNT",
  SAW_PROGRAM = "SAW_PROGRAM",
  FILLED_IN_FIRST_NAME = "FILLED_IN_FIRST_NAME",
  FILLED_IN_LAST_NAME = "FILLED_IN_LAST_NAME",
  FILLED_IN_EMAIL = "FILLED_IN_EMAIL",
  REACHED_END_OF_DIRECT_TO_THERAPIST = "REACHED_END_OF_DIRECT_TO_THERAPIST",
  REACHED_END_OF_DIRECT_TO_COACHING = "REACHED_END_OF_DIRECT_TO_COACHING",
  COMPLETED_ASSESSMENT = "COMPLETED_ASSESSMENT",
  SCHEDULED_FIRST_PHONE_CALL = "SCHEDULED_FIRST_PHONE_CALL",
  COMPLETED_ONBOARDING = "COMPLETED_ONBOARDING",
  BOOKED_DIRECT_TO_THERAPIST = "BOOKED_DIRECT_TO_THERAPIST",
  BOOKED_DIRECT_TO_COACHING = "BOOKED_DIRECT_TO_COACHING",
  CONFIRMED_COVERAGE = "CONFIRMED_COVERAGE",
}
const plugins = [];

const Analytics = AnalyticsConfig({
  app: "brightline",
  plugins: plugins,
});

Analytics.on("track", ({ plugins, payload }) => {
  // Use ahoy tracking for conversion events
  const properties = {
    ...JSON.parse(payload.properties),
    platform: "ahoy",
    bl_event_name: payload.event,
  };
  ahoy.track("AnalyticsEvent:Tracked", properties);
});

export default Analytics;
