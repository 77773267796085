import { Controller } from "@hotwired/stimulus";
import { getCookies, setCookie } from "@js/helpers/cookies";

const NOTIFICATION_COOKIE_DURATION_IN_DAYS = 1;

export default class extends Controller {
  static targets = ["badge"];

  static get values() {
    return {
      count: {
        type: Number,
        default: 0,
      },
    };
  }
  declare countValue: number;
  declare readonly badgeTarget: Element;

  initialize() {
    if (!getCookies().has_viewed_notifications && this.countValue != 0) {
      this.toggleNotificationBadge(true);
    }
  }

  viewNotifications() {
    setCookie(
      "has_viewed_notifications",
      "true",
      NOTIFICATION_COOKIE_DURATION_IN_DAYS
    );
    this.toggleNotificationBadge(false);
  }

  toggleNotificationBadge(isVisible) {
    (this.badgeTarget as HTMLSpanElement).style.display = isVisible
      ? "flex"
      : "none";
  }

  closeMenu() {
    this.dispatch("close", { cancelable: false });
  }
}
