import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

import Analytics from "../helpers/analytics";

/*
This controller handles some analytics, but it's a shaky implementation.
Ideally we would figure out how to get this to work with Turbo or turbolinks.
It will likely break when we switch it on, so if this stops working perhaps
we've enabled Turbo(links).

  This controller handles analytics tracking with external platforms
  (e.g., Facebook, LinkedIn, Google)

  Attributes:

  - data-analytics-event [string]:
      The name of the event to be tracked.
  - data-analytics-metadata [object]:
      An object of additional data to be sent to each analytics platform.

  Actions:

  Use any of the following actions to trigger the Analytics.track call.

  - data-action="click->analytics#railsUJSLinkClick"
  - data-action="click->analytics#track"
  - data-action="analytics#formSubmit" (for use on form elements)
*/
export default class extends Controller {
  railsUJSLinkClick(event: Event) {
    const eventName = this.data.get("event");
    const metadata = this.data.get("metadata") || "{}";
    if (!eventName) return;

    event.preventDefault();
    Analytics.track(eventName, metadata, () => {
      if (this.isLinkElement(event.target)) {
        Rails.handleMethod.call(event.target, event);
      }
    });
  }

  track(event: any) {
    if (event.key && event.key !== "Enter") return;

    const eventName = this.data.get("event");
    const metadata = this.data.get("metadata") || "{}";
    if (!eventName) return;

    Analytics.track(eventName, metadata);
  }

  formSubmit(event: Event) {
    const eventName = this.data.get("event");
    const metadata = this.data.get("metadata") || "{}";
    if (!eventName) return;

    event.preventDefault();
    Analytics.track(eventName, metadata, () => {
      if (this.isFormElement(event.target)) {
        event.target.submit();
      }
    });
  }

  private isLinkElement(target: any): target is HTMLAnchorElement {
    return !!target.parentNode;
  }
  private isFormElement(target: any): target is HTMLFormElement {
    return !!target.parentNode;
  }
}
