const colors = require("tailwindcss/colors");
const plugin = require("tailwindcss/plugin");

const newColors = {
  // purples
  "grape": "#573977",
  // browns
  "darkBrown": "#403D3B",
  "mediumBrown": "#74706D",
  "lightBrown": "#BCB5B0",
  // greens
  "seaGlass": "#9EDCD0",
  // oranges/yellows
  "marigold": "#F6AF5B",
  "pumpkin": "#F97316",
  "lightYellow": "#FEF1C3",
  // whites
  "almond": "#FAF2EC",
  "cream": "#FDF7F4",
  "off-white": "#F8F7F5", // Deprecated in favor or new-gray-100
};

module.exports = {
  // anything nested directly under theme will remove the corresponding defaults
  // anything nested under `extend` will coexist
  theme: {
    fontFamily: {
      sans: ["var(--display-font)", "sans-serif"],
      body: ["var(--display-font)", "sans-serif"],
      walsheim: ['"GT Walsheim"', "sans-serif"],
    },
    colors: {
      "transparent": "transparent",
      "current": "currentColor",
      "black": colors.black,
      "white": colors.white,
      // Prism 2.0
      "gray": {
        100: "#FAFAFA",
        200: "#F2F3F6",
        300: "#E5E8EC",
        400: "#CACFD6",
        500: "#8C93A0",
        600: "#5F6776",
        700: "#373E4A",
        800: "#0C1E2A",
      },
      "green": {
        100: "#F7FFFD",
        200: "#ECFBF8",
        300: "#E0F7F2",
        400: "#C4ECE4",
        500: "#9FDCD0",
        600: "#44A18E",
        700: "#007960",
        800: "#04584D",
      },
      "blue": {
        100: "#F3F8FF",
        200: "#E2EFFF",
        300: "#D7E7FD",
        400: "#AFCEFA",
        500: "#83B8FE",
        600: "#338BFF",
        700: "#096AEC",
        800: "#0B5DCB",
      },
      "orange": {
        100: "#FEF8F4",
        200: "#FFEFDD",
        300: "#FEDFBA",
        400: "#FFCE94",
        500: "#FFB153",
        600: "#F69230",
        700: "#E8680C",
        800: "#CD5703",
      },
      "new-purple": {
        100: "#F9F8FA",
        200: "#ECE5F2",
        300: "#E5D6F2",
        400: "#DCBFF1",
        500: "#C295E3",
        600: "#A171C6",
        700: "#7B5B93",
        800: "#614774",
      },
      "new-yellow": {
        100: "#FFFCF4",
        200: "#FFFAEC",
        300: "#FFF6DB",
        400: "#FEF1C3",
        500: "#FFECAA",
        600: "#FEE285",
        700: "#FDD757",
        800: "#FABB52",
      },
      "new-red": {
        100: "#FFF7F5",
        200: "#FFECE9",
        300: "#FFD8D3",
        400: "#FFC3BC",
        500: "#FD968A",
        600: "#FF6550",
        700: "#DD341D",
        800: "#C22712",
      },
      "mint": "#9FDCD0", // Deprecated in favor or new-green-800
      "deepMint": "#63A094", // Deprecated in favor or new-gray-600
      "offWhite": "#F2F4F5", // Deprecated in favor or new-gray-100
      "salmon": {
        // Deprecated in favor or new-red-*
        100: "#FFC4AE",
        200: "#FFA382",
        300: "#FF8E66",
        400: "#F87C5C",
        500: "#E76B4B",
      },
      "red": {
        // Deprecated in favor or new-red-*
        100: "#F8E4E1",
        200: "#F2CBC5",
        300: "#E79A8F",
        400: "#DB6858",
        500: "#DB3A24",
        600: "#CC1E07",
      },
      "yellow": {
        // Deprecated in favor or new-yellow-*
        ...colors.yellow,
        50: "#FFFAEB",
        100: "#FFE79C",
        200: "#FFF28B",
        300: "#FFD85D",
      },
      "purple": {
        // Deprecated in favor or new-purple-*
        100: "#E4DEEA",
        200: "#CABDD4",
        300: "#A28CB4",
        400: "#7A5B94",
        500: "#A171C6",
        700: "#7B5B93",
      },
    },
    extend: {
      flex: {
        2: "2 2 0%",
      },
      margin: {
        18: "4.5rem",
        26: "6.5rem",
        30: "7.5rem",
        80: "20rem",
      },
      leading: {
        3.5: "0.875rem",
        7.5: "1.875rem",
      },
      padding: {
        3: "0.75rem",
        18: "4.5rem",
      },
      height: {
        "18": "4.5rem",
        "22": "5.5rem",
        "42": "10.5rem",
        "47": "11.75rem",
        "54": "13.5rem",
        "90": "22rem",
        "115": "28.75rem",
        "144": "36rem",
        "160": "40rem",
        "200": "50rem" /* 800px */,
        "screen--22": "calc(100vh - 5.5rem)",
        "inherit": "inherit",
      },
      width: {
        38: "9.5rem",
        45: "11.25rem",
        112: "28rem",
        120: "30rem",
        150: "37.5rem",
      },
      maxWidth: {
        "2xs": "16rem",
        "wrapper": "37.5rem",
        "1/2": "50%",
        "2/3": "66%",
        "3/4": "75%",
        "24": "6rem",
        "32": "8rem",
        "40": "10rem",
        "60": "15rem",
        "64": "16rem",
        "72": "18rem",
        "80": "20rem",
        "120": "30rem",
        "150": "37.5rem" /* 600px */,
        "160": "40rem" /* 640px */,
        "20ch": "20ch",
        "25ch": "25ch",
        "30ch": "30ch",
        "35ch": "35ch",
      },
      minWidth: {
        "1/2": "50%",
        "4/5": "85%",
        "14": "3.5rem",
        "16": "4rem",
        "20": "5rem",
        "40": "10rem",
        "48": "12rem",
        "64": "16rem",
        "80": "20rem",
        "128": "32rem",
        "150": "37.5rem",
      },
      maxHeight: {
        "88": "22rem",
        "108": "27rem",
        "116": "29rem",
        "screen--22": "calc(100vh - 5.5rem)",
        "screen--50": "calc(100vh - 12.5rem)",
      },
      minHeight: {
        "9.5": "2.375rem",
        "160": "40rem",
        "screen--22": "calc(100vh - 5.5rem)",
      },
      borderRadius: {
        "2.5xl": "1.25rem",
      },
      borderWidth: {
        3: "3px",
      },
      fontSize: {
        "2xs": "0.625rem",
        "1.5xl": "1.375rem",
        "2.5xl": "1.75rem",
        "4.5xl": "2.5rem",
        "5.5xl": "3.5rem",
      },
      boxShadow: {
        "heavy-gray":
          "0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.1)",
        "light-gray":
          "0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1)",
        "b-hairline": "0 1px 0 rgba(0, 0, 0, 0.08)",
        "t-hairline": "0 -1px 0 rgba(0, 0, 0, 0.08)",
        "l-edge": "-1px 0 0 rgba(0, 0, 0, 0.08)",
        "t-edge": "0 -1px 0 rgba(0, 0, 0, 0.08)",
        "soft-light": "2px 2px 0 rgba(0, 0, 0, 0.08)",
      },
      gridTemplateColumns: {
        "chat-channel": "auto minmax(360px, 33%) 1fr minmax(90px, 8%)",
        "mss-conversation": "minmax(360px, 33%) 1fr minmax(90px, 8%) auto auto",
      },
      letterSpacing: {
        "very-tight": "-0.15625em",
        "rather-tight": "-0.09375em",
        "tight": "-0.0625em",
        "slightly-tight": "-0.03125em",
        "slightly-wide": "0.03125em",
        "wide": "0.0625em",
        "rather-wide": "0.09375em",
        "very-wide": "0.125em",
        "extra-wide": "0.15625em",
        "negative-2.5px": "-2.5px",
        "negative-2px": "-2px",
        "negative-1.5px": "-1.5px",
        "negative-1px": "-1px",
        "negative-0.5px": "-0.5px",
        "0px": "0px",
        "0.5px": "0.5px",
        "1px": "1px",
        "1.5px": "1.5px",
        "2.5px": "2.5px",
      },
      lineHeight: {
        "5.5": "1.375rem",
        "6.5": "1.625rem",
        "7.5": "1.875rem",
        "8.5": "2.125rem",
        "11": "2.75rem",
        "12": "3rem",
        "14": "3.5rem",
        "extra-tight": "1.15",
      },
      colors: newColors,
      transitionProperty: {
        height: "max-height, height",
      },
      animation: {
        remind: "small-bounce 1s 1.5",
        blip: "small-pulse 1s 1",
      },
      keyframes: {
        "small-bounce": {
          "0%, 100%": {
            "transform": "translateY(-5%)",
            "animation-timing-function": "cubic-bezier(0.8, 0, 1, 1)",
          },
          "50%": {
            "transform": "translateY(0)",
            "animation-timing-function": "cubic-bezier(0, 0, 0.2, 1)",
          },
        },
        "small-pulse": {
          "10%": { transform: "scale(1.2)" },
        },
      },
      screens: {
        "mobile-hover": { raw: "(hover: hover)" },
      },
      scale: {
        "-100": "-1",
      },
      inset: {
        "1/5": "20%",
        "2/5": "40%",
        "3/5": "60%",
        "4/5": "80%",
        "22": "5.5rem",
        "50": "12.5rem",
      },
      zIndex: {
        "-10": "-10",
      },
    },
    objectPosition: {
      "50-70": "50% 70%",
    },
    fill: {
      current: "currentColor",
      none: "none",
    },
  },
  variants: {
    extend: {
      animation: ["hover", "focus"],
      borderRadius: ["first", "last"],
      borderWidth: ["first", "last"],
      borderColor: ["disabled"],
      cursor: ["disabled"],
      display: ["group-hover"],
      opacity: ["disabled", "group-hover"],
      visibility: ["group-hover"],
      textColor: ["hover", "disabled", "group-focus"],
      backgroundColor: ["disabled", "even", "odd", "group-focus"],
      pointerEvents: ["disabled"],
    },
  },
  corePlugins: {
    aspectRatio: false,
  },
  plugins: [
    require("@tailwindcss/forms"),
    require("@tailwindcss/aspect-ratio"),
    require("tailwind-scrollbar-hide"),
    // Extends utility classes to support more complex border color layouts
    // e.g., Make the y axis one color and the x axis another
    plugin(function ({ addUtilities }) {
      const newUtilities = {
        ".border-y-transparent": {
          "border-top-color": "transparent",
          "border-bottom-color": "transparent",
        },
        ".border-x-transparent": {
          "border-left-color": "transparent",
          "border-right-color": "transparent",
        },
      };

      for (const [color, value] of Object.entries(newColors)) {
        newUtilities[`.border-x-${color}`] = {
          "border-left-color": value,
          "border-right-color": value,
        };

        newUtilities[`.border-y-${color}`] = {
          "border-top-color": value,
          "border-bottom-color": value,
        };
      }

      addUtilities(newUtilities, ["hover"]);
    }),
  ],
};
