import { Controller } from "@hotwired/stimulus";
const SELECTED_CLASSES = [
  "selected",
  "font-bold",
  "border-b-4",
  "border-blue-700",
];

export default class extends Controller {
  static targets = ["button", "content", "empty"];

  filter(event) {
    const tab = event.currentTarget.dataset.tab;
    this.setSelected(event);

    if (tab === "all") return this.reset();

    let contentsFound = false;
    this.contentTargets.forEach((el) => {
      if (el.dataset.tab === tab) {
        el.parentNode.style.display = "block";
        contentsFound = true;
      } else {
        el.parentNode.style.display = "none";
      }
    });

    this.emptyTargets.forEach((el) => {
      el.parentNode.style.display = "none";
    });

    if (!contentsFound) {
      this.emptyTargets.forEach((el) => {
        if (el.dataset.tab === tab) {
          el.parentNode.style.display = "block";
        }
      });
    }
  }

  setSelected(event) {
    const triggers = this.element.querySelectorAll("[data-tab]");

    triggers.forEach((trigger) => {
      trigger.classList.remove(...SELECTED_CLASSES);
    });

    event.currentTarget.classList.add(...SELECTED_CLASSES);
  }

  reset() {
    this.contentTargets.forEach((el) => {
      el.parentNode.style.display = "block";
    });

    this.emptyTargets.forEach((el) => {
      el.parentNode.style.display = "none";
    });
  }
}
